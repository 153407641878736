<template>
    <div class="release-detail release">
        <b-card>
            <div class="text-center mt-2 mb-3">
                <!-- {{ release_notes }} -->
                <h1 class="mb-1">{{release_notes.version}}</h1>
                <div class="date">{{release_notes.release_date}}</div>
            </div>
            <div class="d-flex notes-heading">
                <div class="release-describe">
                    <p class="pr-md-5">{{ release_notes.description }}</p>
                    <!-- <p class="pr-md-5">We're thrilled to announce the release of Version 2.0 of our product! This update brings significant enhancements, including faster performance, a modernized user interface, and a host of new features designed to elevate your experience. Explore the new version and share your feedback with us! Thank you for your support.</p> -->
                </div>
                <div class="position-relative quick-links">
                    <h3 class="main-header mb-1">Quick Links</h3>
                    <b-carousel id="release-note" :interval="10000" >
                    <b-carousel-slide key="index">
                        <ol class="m-0 p-0">
                            <li class="list-auto mb-1" v-for="feature in release_notes.release_detail" :key="feature.name"  v-b-toggle.sidebar-relase-view @click="viewNote(feature)">
                                <b-link>{{feature.title}}</b-link>
                            </li>
                        </ol>
                    </b-carousel-slide>
                    </b-carousel>
                </div>
            </div>
            <ul class="mx-0 p-0 mt-5">
                <li :id="feature.title.replace(/\s+/g, '')"   class="list-unstyled my-3" v-b-toggle.sidebar-relase-view v-for="feature in release_notes.release_detail" :key="feature.title"  @click="viewNote(feature)">
                    <div class="overall-view">
                        <div class="d-flex align-items-center justify-content-between">
                            <b-badge variant="warning" pill>{{ feature.type }}</b-badge>
                        </div>
                        <h2 class="my-1">{{feature.title}}</h2>
                        <p class="description" v-html="truncateText(feature.description,240)">
                        </p>
                        <span class="font-weight-bolder text-right see-more">
                            <u>...see more</u>
                        </span>
                        <!-- <div class="reference-view">
                            <b-img-lazy fluid :img-src="require('@/assets/images/whats_new/'+feature.attachment)" 
                            @click="openImage(require('@/assets/images/whats_new/'+feature.attachment))" :alt="feature.title"/>
                        </div> -->
                    </div>
                </li>
            </ul>
            <!-- Sidebar Design -->
            <b-sidebar id="sidebar-relase-view"
            ref="sidebar_view"
            bg-variant="white"
            sidebar-class="sidebar-lg post-card modal-responsive-width"
            shadow
            backdrop
            right
            width="60%"
            no-header>
            <template>
                <!-- Header -->
                <div class="b-sidebar-header">
                    <div class="d-flex align-items-center justify-content-between">
                        <b-badge variant="warning" pill>{{ selectedFeature.type }}</b-badge>
                        <b-button variant="primary" pill to="/" class="try-it d-md-none d-sm-flex">Try It</b-button>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <h2 class="my-1">{{ selectedFeature.title }}</h2>
                        <b-button variant="primary" pill to="/" class="try-it d-md-flex d-none">Try It</b-button>
                    </div>
                    <b-link class="floating-close d-flex align-items-center justify-conten-center" v-b-toggle.sidebar-relase-view >
                        <feather-icon icon="XIcon" size="20" class="w-100" />
                    </b-link>
                </div>
                <!-- End Header -->
                <b-card class="body-content shadow-none" v-if="selectedFeature">
                    <p class="description" v-html="selectedFeature.description"> <br>
                        </p>
                        <!-- We have fine-tuned the application’s underlying code structure to ensure faster startup times. This optimization minimizes the load on system resources, allowing users to access the software more quickly and efficiently, enhancing the overall user experience </p>
                    <p>Oftentimes, I have wondered why do software companies charge a lot of money to use their products and services? A few years ago, we signed up for a marketing automation platform with one of the leading providers in the market. I thought that the price we paid to get started was reasonable. However a year later, our price increased by 20 or 30%.  The price started increasing year over year. We were billed based on usage (or) the # of contacts.</p>
                    <p>Software companies operate on high margins. It is good for them because they generate a lot of cash and hopefully they are able to redeploy in the right way.  Not all businesses operate with the same margin as software. Distributors (and mostly manufacturers) operate on thin margins. It is very hard to run a physical store. </p> -->
                    <div class="reference-view" v-if="selectedFeature && selectedFeature.attachment">
                        <b-img-lazy fluid :src="require('@/assets/images/whats_new/'+selectedFeature.attachment)" alt="goal-img"/>
                    </div>
                </b-card>
            </template>
            </b-sidebar>
            <!-- End Sidebar Design -->
        </b-card>
    </div>
</template>
<script>
import store from "@/store";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
  BBadge,
  BProgress,
  BProgressBar,
  BSidebar,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import vSelect from "vue-select";
import $ from "jquery";
import { releaseData } from "../../assets/jsons/release_notes.js"
import whats_new from "@/assets/jsons/whats_new.json"
Vue.use(VueCookies);
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VBTooltip,
    BBadge,
    BProgress,
    BProgressBar,
    BSidebar,
    VBModal,
  },
  data() {
    return {
        release_notes:[],
        selectedFeature: {},
    };
  },
  created(){
    this.release_notes = releaseData[0];
    console.log(releaseData[0]);

  },
  methods: {
    truncateText(text, limit) {
        if (text.length > limit) {
            return text.substring(0, limit) + '...';
        }
        return text; // Return the full text if it's shorter than the limit
    },
    viewNote(feature) {
        this.selectedFeature = feature;
      
    },

    
  }
};
</script>